.main-content {padding: 1.2em; max-width: 30em; margin: auto auto;}
.main-content p {font-size: 13px;}
.main-content a {font-weight: bold; text-decoration: none; color: var(--main-backgroundcolor);}



.main-content p.error { max-width: 30em; margin-bottom: 0.3em!important; background-color: var(--ion-color-danger);  color:white; padding: .7em .9em; font-weight: bold;}
.main-content p.error.center {margin:auto;}

.main-content h2, ion-item ion-label, ion-label {font-size: .9rem; font-weight: bold;}

/* Navigation */
#main-nav-list {padding: 0;}
ion-toolbar, #main-nav-list ion-item.selected {--background: var(--main-backgroundcolor)!important; --color: var(--main-color);}
#main-nav-list ion-item.selected ion-icon {color: var(--main-color); }

/* Header */
ion-header ion-menu-button {color: var(--main-color);}
/*
ion-header ion-toolbar {background-color: pink; }
ion-header ion-toolbar ion-grid {background-color: khaki; padding: 0; margin: 0; min-height: 1.5em;}
ion-header ion-toolbar ion-grid ion-row {background-color: teal;  padding: 0; margin: 0;}
*/
ion-header .back-button { display: block; margin-left: .3em; font-size: 1.2em; --color: white;}
ion-header .company-name {display: inline-block; vertical-align: bottom; margin-bottom: -.25em; width: 100%; font-size: .9em; overflow:hidden; text-overflow: ellipsis;  white-space:nowrap;  }
ion-header ion-grid ion-row ion-img { position: absolute; margin-top: -.5em; width: 4em;}
@media screen and (max-width: 900px) {
    ion-header .company-name {margin-top: -1em;}
    ion-header .header-logo ion-img{width: 85%;}
}

@media screen and (max-width: 640px) {
    ion-header ion-grid, ion-header ion-grid ion-col, ion-header ion-grid ion-col ion-title {padding:0; margin: 0;}
    ion-header ion-grid ion-col ion-title:first-of-type {font-size: 14px; font-weight: 500;}
    ion-header .company-name {margin-bottom: 0;}
    ion-header ion-grid ion-row ion-img {width: 3.5em;}

    ion-header .header-logo ion-img{width: 98%;}
}


/* navigation */
ion-menu ion-item {font-size: 14px; font-weight: normal; --color: rgb(102, 102, 102);}
/* Loading */
.loading-container.full {position: absolute; top: 0; left: 0; z-index: 1111; width: 100%; height: 100%;  background-color: rgba(209, 209, 209, 0.5); }
.loading-container.full ion-spinner {position: absolute; top: 47.5%; left: 47.5%; transform: scale(2.5)}
.item-list ion-spinner {display: block; margin: 1.2em auto;}

/* Forms */
ion-checkbox { --background-checked: var(--main-backgroundcolor); --border-color-checked: #c1c1c1; }
ion-button { --background: var(--main-backgroundcolor)}

/* Login Form */
.login-container { max-width: 25em; min-height: 15em; margin: auto auto; margin-top: 2.5em; padding: 1.5em 1em; border: solid thin black; border-radius: 1em; box-shadow: 0.1em 0.2em 0.5em 0.1em #adadad;}
.login-container ion-item {--inner-border-width: 0; --border-width: 0; --highlight-height: 0;} 
.login-container ion-label { font-weight: bold; --inner-border-width: 0; --border-width: 0; --highlight-height: 0;}
.login-container ion-input { border: solid thin gray; margin-top: 0.5em;}
.login-container ion-item.terms-dataprotection {margin-top: 1.5em; border: none; font-size: 12px; line-height: 1.75; }
.login-container ion-item.terms-dataprotection ion-label {display: block; width: 100%; margin-left: 1.5em; font-weight: 500;}

/* ordering form */
.content-amount-type ion-item { height: 40px; border: solid thin gray;  border-radius: .25em;}
.content-amount-type ion-item {margin: 0!important; padding: 0!important; padding-top: 0!important; --padding: 0!important; } 
.content-amount-type ion-item > *  {min-height: 15px!important; --padding-top: 0.1em; --padding-bottom: 0.8em; font-size: 14px; }
.content-amount-type ion-label { display: inline-block;margin-bottom: .3em;}

ion-item.none-border {--border-color:transparent; border: none;}

/* text / typographic */
.strong  {font-weight: 550;}
.small-info, ion-text.small-info {font-size: 13px; font-style: italic;}
.text-center {text-align: center;}
p.no-result-message { margin-top: 2.5em;font-size: 1.25em;}


/* Welcome Page */
.main-content.welcome { width: 100%; max-width: inherit; min-height: 100%; margin-top: 0; background-color:var(--main-backgroundcolor);}
.welcome .main-logo {margin-top: 2.5em;}
.welcome .button-group ion-button {display: block; min-height:3.5em; margin:auto; margin-top: 3em; --background-activated: var(--main-backgroundcolor); --color:white; text-transform: capitalize; --border-width: 1px; --border-radius: 1em; --box-shadow: .15em .15em .1em .1em black; /* border:solid thin black; */} 
.welcome .button-group ion-button::part(native){  line-height: 1.3; }
.welcome .app-logo.buttom {margin-top: 2.5em;}


/* restart container */
.restart-container { margin-bottom: 4em;}
.restart-container ion-button {float: right;}

/* reload icon */
.reload-icon{font-size: 1.5em; margin-top: .6em;}
.reload-icon.small {font-size: 1em;}
.reload-icon.large {font-size: 2.5em;}

.reload-icon.center {display: block; margin: auto;}
.reload-icon.right {float: right;}
.reload-icon:hover {cursor: pointer;}
/* Search Bar && Introduction */
.introduction-container, .search-container {clear: both;} 
.search-container .error-message { display: block; float: right; width: 88%; padding: 0.5em 2em; margin: .2em .7em 0 0; background-color: rgb(216, 216, 216); font-size: 1rem; }

@media screen and (max-width: 640px) { 
    .search-container ion-searchbar {padding: 0;}
    .introduction-container h1 { font-size: 1.2em;}
}

/* alert container */

ion-alert .alert-head {background-color: var(--main-backgroundcolor); }
ion-alert .alert-head * {color: var(--main-color)!important;}
ion-alert .alert-message {margin-top: 1em;}
ion-alert .alert-button-group button {border: solid thin gray; border-radius: 1em;}

ion-alert.restart .alert-button-group .do {min-width: 5em; background-color: var(--main-backgroundcolor); color: var(--main-color); font-weight: bold; border: none;}
ion-alert.restart .alert-button-group .do .alert-button-inner, ion-alert .alert-button-group button:not(.alert-button-role-cancel) .alert-button-inner {justify-content: center;}


ion-alert.error .alert-head {background-color: rgb(243, 67, 67);}

/* Item List - Selection of item */
.item-list, .item-list ion-item { --background: var(--main-backgroundcolor); color: var(--main-color)}
.item-list ion-item {/*border-bottom: white solid 2px;*/ margin-bottom: .3em;}
.item-list:not(.non-hover) ion-item:hover {cursor: pointer;}
.item-list ion-item * {-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
.item-list ion-item p {font-size: 17px; font-weight: bold; }
.item-list ion-item span.small {font-size: 15px;font-weight: normal;}

.item-list p.empty-message {color:black; text-align: center; font-size: 1.1em;}

@media screen and (max-width: 640px) {
    .item-list p.empty-message { font-size: .9em;}
}

.ordering .ordering-continue-button {margin-bottom: 6em;}

/* Transporter Grid */ 
.transporter-grid {margin: 1.2em 0 0 0; padding: 0; }

.transporter-grid ion-col { flex: 0 0 calc(calc(4 / var(--ion-grid-columns, 12.35)) * 100%)!important;
                            height: 2.8rem; margin: .1em .1em 0 0; padding-top: .7rem; 
                            background: #626262; 
                            color: white; text-align: center; font-size: .7rem; font-weight: bold;
                            -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
.transporter-grid ion-col:hover {cursor: pointer;}
.transporter-grid ion-col.active {background-color: rgb(59, 189, 59);}
.transporter-grid ion-col ion-icon {float: right; position: relative; bottom: .08em; z-index: 11; height: 24px; overflow: visible; font-size: 1px!important; }

/* Date- and time picker */ 
.date-and-time-container ion-datetime { border-bottom: solid thin var(--main-backgroundcolor); text-align: center;}
.check-time ion-label, .check-time ion-checkbox, .date-and-time-container ion-datetime {margin-bottom: 0.8em;}
.date-and-time-container ion-datetime  {font-size: 14px;}
.date-and-time-container ion-label {font-size: 12px!important; font-weight: bold; }
.date-and-time-container ion-checkbox {margin: 0 .4em 0 0!important;}

/* comment-box */
ion-textarea.comment-box  {padding: .5em; margin-bottom: 2em; border:solid thin gray; border-radius: .25em; background-color: rgba(145, 145, 145, 0.111);}

/* ordering summary */
.order-summary p.error {max-width: inherit;}

.order-summary, .order-success-container {margin-top: 3em;}
.order-summary ion-row { padding: .8em 1em; ;border-top: solid 1px var(--main-backgroundcolor); font-size: 12px; }
.order-summary ion-row.no-border {border:none;}
.order-summary ion-row.header {padding: .4em 0; background-color: var(--main-backgroundcolor); color: var(--main-color); font-size: 15px; font-weight: 600; text-align: center;}
/*.order-summary ion-row.material-row {font-size:2em;} */
/*.order-summary ion-row.comment-box p { min-height: 1em;}*/
.order-summary ion-row span.col-headline { display: inline-block; padding-bottom: 0.2em;  font-size: 14px; line-height: 1.3; }
.order-summary ion-row ion-col.right-side-content {text-align: right;}

/* ordering success */
ion-button.ordering-success { font-size: .6em; width: 100%;} 

/* list of orders */
.main-content.orders ion-list, .main-content.orders ion-item {overflow: visible;}
ion-item.order { --padding-start: 0; --padding-end: 0; width: 105%; }
ion-item.order ion-grid { padding-right: 2.5%}
.order-detail.updated, .order-detail.updated  {background-color: rgb(120, 189, 120);}

.order-detail.deleted {background-color: rgb(192, 60, 60)}
.order-detail ion-icon.button { float: right; border: solid thin white; border-radius: .6em; background-color: white; margin: 0.1em;}
.order-detail ion-icon.button:hover {cursor: pointer;}
.order-detail ion-icon.updated{fill: rgb(84, 241, 84)}
.order-detail ion-icon.deleted{fill: rgb(243, 67, 67);}

.order-detail ion-col.delete.fully a {color: white; font-size: 12px; border: solid 2px white; padding: 12px; background-color: rgb(151, 15, 15); float:right; /* box-shadow: 0.1em .1em .1em .1em  rgb(104, 104, 104);*/}
.order-detail ion-col.delete.fully a:hover {cursor: pointer;}
.order-detail .delivery-time {background-color: rgb(219, 219, 219); padding: .05em .25em; text-align: center;}
.order-detail .delivery-time p {  color: #3c3737; font-size: .8em; font-weight: 500;}
.order-detail .details {border-bottom: solid thin white;}
.order-detail .details.non-border { border: none;}
.order-detail .details p{ padding-bottom: 1em; font-size: .8em; font-weight: normal; text-align: center; line-height: 1.5; }

.order-detail .comment {min-height: 5em; background-color: rgb(219, 219, 219); }
.order-detail .comment p{ font-size: .8em; font-weight: normal; text-align: center; line-height: 1.0; color: #3c3737;}
.order-detail p.bold {font-weight: 900;}
.order-detail p.medium-size {font-size:95%;}
.order-detail .status-box {/*position: relative; left: 1em;*/ margin-top: 0.5rem;  }
.order-detail .status {  background-color: rgb(79, 79, 79); border: solid thin silver; box-shadow: -.2em .2em .8em .1em  #aa672c; }
.order-detail .status {  box-shadow: -.2em .2em .8em .1em  #aa672c; }
.order-detail.updated .status {  box-shadow: -.2em .2em .8em .1em  #29642d; }
.order-detail.deleted .status {  box-shadow: -.2em .2em .8em .1em  #240e0e; }
.order-detail .status * { font-size: 13px; text-align: center;}
.order-detail p.important, .order-detail .important { text-decoration: underline;}

/* delivery */
.delivery.main-content {padding: 0.5em; max-width: 35em;}
.delivery .dates {margin-bottom: 1em;}
.delivery .datepicker-title {font-size: 15px; margin-left: 1em; }
.delivery .react-datepicker-wrapper input {width: 100%;  margin-top: .3em; padding: .2em; border-radius: .5em; text-align: center; }
.delivery .react-datepicker-popper { opacity: 1; z-index:10; }
.delivery ion-label {display: block;  width: 100%;  padding-left: .5em; font-weight: 300; font-size: 14px; text-align: center; }
.delivery ion-select {margin-top: 1em; border: solid thin #b5b5b5; border-radius: 0.8em; font-weight: normal;}

/* switch between modes [daily | total] */
.delivery .mode-switch ion-col:first-of-type { height: 40px; line-height: 40px;  vertical-align: center; text-align: center; font-style: italic;} 
.delivery .mode-switch ion-toggle {--handle-background: var(--main-backgroundcolor); --handle-background-checked: var(--main-backgroundcolor); --background-checked:rgb(230, 188, 144);}

/* delivery data result(s) */
.delivery ion-list, .delivery ion-item.delivery {--background: rgb(216, 216, 216); --background-color: red; color: black;}
.delivery .delivery-items:not(.empty) { margin-top: 2em; background-color: rgb(216, 216, 216);  padding: .4em 0 0  0;}

/* datepicker */
.delivery .react-datepicker__day--selected {background-color: var(--main-backgroundcolor);}
.react-datepicker__header {background-color: var(--main-backgroundcolor);}

.react-datepicker__navigation-icon::before, .react-datepicker__navigation-icon::after { border-color: var(--main-color)}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after { border-bottom-color: var(--main-backgroundcolor);}
.react-datepicker__header * { color: var(--main-color);}
.react-datepicker__input-container input:focus {outline: none;}

.delivery .delivery-items  ion-item::part(native) {padding: 0.1em;}
.delivery .delivery-items > ion-item {margin-bottom: 1.3em;}
.delivery .delivery-items > ion-item > ion-grid, .delivery .delivery-items ion-col { padding: 0}

.delivery .delivery-items .content-container {font-size: 12px;}
.delivery .delivery-items .content-container.small {height: 28px;}
.delivery .delivery-items .content-container > ion-grid {background-color: white; }
.delivery .delivery-items .content-container:first-of-type {padding-right: .2em; padding-left:.8em;}
.delivery .delivery-items .content-container:last-of-type {padding-left: 1.5em;}

.delivery .delivery-items .delivery-date { text-decoration: underline; font-weight: bold;}
.delivery .delivery-items .material { font-weight: bold; margin: .75em 0;}
.delivery .delivery-items .order-amount, .delivery .delivery-items .progress {margin: 1.2em 0 2em 0;}
.delivery .delivery-items .order-amount ion-col:last-of-type,
.delivery .delivery-items .order-time ion-col:last-of-type,
.delivery .delivery-items .ordered-by ion-col:last-of-type {font-weight: bold;}

.delivery .delivery-items .progress {margin-bottom: 0.4em;}
.delivery .delivery-items .progress ion-progress-bar {--background: rgb(248, 216, 187); --progress-background: rgb(233, 113, 0); height: .7em;}
.delivery .delivery-items .progress-value {font-weight: bold;}
.delivery .single-delivery-item {margin-bottom: 0.4em; border-bottom: 2px solid rgb(87, 87, 87);}
.delivery .single-delivery-item  ion-grid ion-col, .delivery .complete-scaled-amount  ion-grid ion-col {text-align: right;}
.delivery .single-delivery-item ion-grid > ion-row {margin-top: .65em;}
.delivery .single-delivery-item ion-row {margin-bottom: .65em;}
.delivery .single-delivery-item ion-img.libra-img {position: relative; bottom: .3em; width: 18px; margin-bottom: .2em; }

.delivery .complete-scaled-amount { margin-top: .5em;}
.delivery .complete-scaled-amount .content-container {padding-right: 0!important; }
.delivery .complete-scaled-amount .sum {text-decoration: underline;}

@media screen and (max-width: 640px) {
    .delivery .delivery-items ion-item { --inner-padding-end: .1em;}
    .delivery .delivery-items .content-container:first-of-type {padding-right: 0em; padding-left:0.1em;}
    .delivery .delivery-items .content-container:last-of-type {padding-left: 0.5em; width: 110%;}    
}